import {
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
  } from '../actions/notification';
  
  const initialState = {
    myNotifications: [],
    error: null,
  };
  
  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          myNotifications: action.payload,
          error: null
        };
      case GET_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          myNotifications: [],
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default notificationReducer;
  