import NotificationService from '../services/notification.service';

export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const getNotificationsSuccess = (data) => ({ type: GET_NOTIFICATIONS_SUCCESS, payload: data });
export const getNotificationsFailure = (error) => ({ type: GET_NOTIFICATIONS_FAILURE, payload: error });

export const getMyNotifications = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const result = await NotificationService.getMyNotifications(token);
        console.log(result)
        if (result.success) {
            dispatch(getNotificationsSuccess(result.notifications));
            return result.notifications;
        }
        else {
            dispatch(getNotificationsFailure(result.message));
            return false;
        }
    } catch (e) {
        dispatch(getNotificationsFailure(e.toString()));
        return false;
    }
}
  